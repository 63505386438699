import { SHARED_MODEL, api } from ":mods";
import { ENDPOINTS } from "../../const";
import { ASubmoduleMaterial } from "../../models";

export async function getCollectionDetails(
  id: string | number,
  collection_id: string | number,
  template: ASubmoduleMaterial<3>
): Promise<SHARED_MODEL.ApiResponseSchema<ASubmoduleMaterial<3>>> {
  if (template.design_name !== "tp22_task01") {
    if (template.max_elements <= 1) {
      return Promise.resolve({
        data: template,
      });
    }
  }
  if (collection_id === undefined || collection_id === null) {
    return Promise.resolve({
      data: template,
    });
  }
  let route = ENDPOINTS.material_details_with_material_id_student + id;
  if (collection_id !== -1) route += `/?collection=${collection_id}`;
  return api
    .getAuth(route)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
